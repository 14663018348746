
import { defineComponent } from 'vue';
import RocherPng from '@/assets/img/rocher.png';
import UserPng from '@/assets/img/user.png';

export default defineComponent({
    props: {
        bot: {
            default: true,
            type: Boolean,
        },
        hideAvatar: {
            default: false,
            type: Boolean,
        },
        message: {
            required: false,
            type: String,
        },
        video: {
            required: false,
            type: [Blob, String],
        },
        videoControls: {
            default: true,
            required: false,
            type: Boolean,
        }
    },

    emits: [
        'video-can-play',
        'video-can-play-through',
        'video-ended',
        'video-loaded-data',
        'video-play',
    ],

    data() {
        return {
            videoCanPlay: false,
            videoCanPlayThrough: false,
            videoPlay: false,
        };
    },

    computed: {
        className(): string {
            let className = 'message_text ';
            className += this.bot ? 'bot' : 'user_message';

            if (this.hideAvatar) {
                className += ' hide-avatar';
            }

            return className;
        },

        imgSrc(): string {
            return this.bot ? RocherPng : UserPng;
        },

        videoEl(): HTMLVideoElement | null {
            return document.querySelector('.video > video');
        },

        videoSrc(): string {
            if (!this.video) {
                return '';
            } else {
                return (typeof this.video === 'string') ? this.video :
                    URL.createObjectURL(this.video as Blob);
            }
        },
    },

    methods: {
        onVideoCanPlay(event: Event): void {
            this.videoCanPlay = true;

            this.$emit('video-can-play', event.target);
        },
        onVideoCanPlayThrough(event: Event) {
            this.videoCanPlayThrough = true;

            this.$emit('video-can-play-through', event.target);
        },
        onVideoEnded(event: Event) {
            this.videoPlay = false;

            this.$emit('video-ended', event.target);
        },
        onVideoLoadedData(event: Event) {
            this.$emit('video-loaded-data', event.target);
        },
        onVideoPlay(event: Event) {
            this.videoPlay = true;

            if (this.videoEl) {
                //this.videoEl.muted = false;
            }

            this.$emit('video-play', event.target);
        },
    }
});
