
import { InputSliderPayloadInterface } from '@/interfaces/ChatBotInterface';
import { Pagination } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { defineComponent } from 'vue';

import '@/../node_modules/swiper/swiper-bundle.min.css';

export default defineComponent({
    components: {
        Swiper,
        SwiperSlide,
    },

    props: {
        recipientName: {
            required: true,
            type: String,
        },
        referrer: {
            required: true,
            type: [String, Number],
        },
        slides: {
            required: true,
            type: [Array, Object],
        },
        type: {
            required: true,
            type: String,
        },
    },

    emits: ['complete', 'loaded'],

    setup() {
        return {
            modules: [
                Pagination,
            ],
            pagination: {
                enabled: true,
                el: '.swiper-pagination',
                type: 'bullets'
            } as PaginationOptions,
        }
    },

    mounted() {
        if (this.type !== 'image') {
            this.$emit('loaded');
        }
    },

    data() {
        return {
            swiper: null as (any | null),
            swiperActiveIndex: 0,
        }
    },

    methods: {
        onInit(swiper: any) {
            this.swiper = swiper;
        },

        onInput(value: string, index: number) {
            if (index === this.swiperActiveIndex) {
                this.$emit('complete', {
                    choice: value,
                    referrer: this.referrer
                } as InputSliderPayloadInterface);
            } else {
                this.swiper.slideTo(index);
            }
        },

        onActiveIndexChange(swiper: any) {
            this.swiperActiveIndex = swiper.activeIndex;
        }
    }
});
