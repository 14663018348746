
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        btnAppendClass: {
            default: '',
            type: String,
        },
        btnClickDelay: {
            default: 10,
            type: Number,
        },
        btnLabel: {
            required: true,
            type: String,
        },
        btnType: {
            default: 'button',
            type: String,
        },
        noBorder: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        delayClick(event: PointerEvent) {
            event.preventDefault();

            const target = event.target as HTMLButtonElement;
            target.classList.add('clicked');

            setTimeout(() => {
                this.$emit('btnClick');

                setTimeout(() => target.classList.remove('clicked'), 1_000);
            }, this.btnClickDelay);
        }
    }
})
