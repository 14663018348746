
import BtnStd from '@/components/BtnStd.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        BtnStd,
    },

    props: {
        cta: {
            required: false,
            type: Object,
        },
        show: {
            default: false,
            type: Boolean,
        },
        title: {
            required: false,
            type: String,
        },
        text: {
            required: true,
            type: String,
        },
    },

    methods: {
        close(): void {
            this.$emit('close');
        }
    },
})
