
import { SettingsHelper } from '@/helpers/SettingsHelper';
import {
    Choice3Value,
    Choice4Value,
    Choice5Value,
    ChoiceInterface,
    MessageChoicePayloadInterface
} from '@/interfaces/ChatBotInterface';
import MessageChoice from '@/views/ChatBot/MessageChoice.vue';
import MessageText from '@/views/ChatBot/MessageText.vue';
import FileSaver from 'file-saver';
import { map } from 'lodash';
import { defineComponent } from 'vue';

let stepForwardTimeoutId: any;

export default defineComponent({
    components: {
        MessageChoice,
        MessageText,
    },

    props: {
        greetingId: {
            required: true,
            type: String,
        },
        lottery: {
            required: false,
            type: String,
        },
        pinCode: {
            required: true,
            type: String,
        },
        video: {
            required: true,
            type: String,
        },
    },

    mounted(): void {
        this.toBottom();
    },

    data() {
        return {
            penultimate: false,
            showLargeVideo: true,
            showSweepstakes: true,
            ultimate: false,
        }
    },

    computed: {
        hasSweepstakes(): boolean {
            return SettingsHelper.get('system', 'env', 'hasLottery', false);
        },
        messageChoiceAgain(): ChoiceInterface[] {
            return map(['YES', 'NO'], (value: Choice4Value) => {
                return {
                    value,
                    text: this.$t(`SHARING_03.CHOICES.${value}`),
                }
            })
        },
        messageChoiceSharing(): ChoiceInterface[] {
            const parts: Choice3Value[] = ['SHARE'];

            if (SettingsHelper.get('system', 'env', 'hasTree')) {
                parts.push('SUB0');
            }

            parts.push('DOWNLOAD');

            return map(parts, (value: Choice3Value) => {
                return {
                    sub: (value === "SUB0"),
                    text: this.$t(`SHARING_01.CHOICES.${value}`),
                    value,
                }
            })
        },
        messageChoiceSweepstakes(): ChoiceInterface[] {
            return map(['YES', 'SUB0', 'NO'], (value: Choice5Value) => {
                return {
                    sub: (value === "SUB0"),
                    text: this.$t(`SHARING_05.CHOICES.${value}`),
                    value,
                }
            })
        },
    },

    methods: {
        onMessageChoicePick({ choice, referrer }: MessageChoicePayloadInterface): void {
            if (referrer === 'messageChoiceSharing') {
                choice = choice as Choice3Value;

                if (choice === 'DOWNLOAD') {
                    FileSaver.saveAs(this.video, `Ferrero Rocher Video Greetings.mp4`);

                    this.penultimate = true;

                    this.toBottom();
                } else if (choice === 'SHARE') {
                    if ('share' in navigator) {
                        let {
                            origin,
                            pathname,
                        } = location;

                        if (pathname === '/') {
                            pathname = '';
                        }

                        navigator.share({
                            text: this.$t('SHARING_02.TEXT'),
                            title: this.$t('SHARING_02.TITLE'),
                            url: `${origin}${pathname}/viewer/?g=${this.greetingId}`,
                        }).finally(() => {
                            this.penultimate = true;

                            this.toBottom();
                        });
                    }
                }
            } else if (referrer === 'messageChoiceAgain') {
                choice = choice as Choice4Value;

                if (choice === 'YES') {
                    const {
                        origin,
                        pathname,
                    } = location;

                    location.href = `${origin}${pathname}`;
                } else if (choice === 'NO') {
                    this.ultimate = true;

                    this.toBottom();
                }
            } else if (referrer === 'messageChoiceSweepstakes') {
                choice = choice as Choice5Value;

                if (choice === "YES") {
                    let urlLottery = SettingsHelper.get('system', 'env', 'urlLottery');
                    urlLottery = urlLottery.replace('{{greetingId}}', this.greetingId);
                    urlLottery = urlLottery.replace('{{lottery}}', this.lottery);

                    window.open(urlLottery, "_blank");
                }

                this.showSweepstakes = false;

                this.toBottom();
            }
        },

        onVideoEnded(): void {
            this.showLargeVideo = false;

            this.toBottom();
        },
        onVideoPlay(): void {
            if (stepForwardTimeoutId) {
                clearTimeout(stepForwardTimeoutId);
            }
        },
        onVideoLoadedData(): void {
            stepForwardTimeoutId = setTimeout(() => {
                this.showLargeVideo = false;

                this.toBottom();

                stepForwardTimeoutId = null;
            }, 5_000);
        },

        toBottom(): void {
            this.$nextTick(() => {
                window.scrollTo(0, document.body.scrollHeight);
            })
        },
    },
});
