
import {version} from '@/../package.json';
import BtnStd from '@/components/BtnStd.vue';
import LoaderAnimation from '@/views/DeliveryBot/LoaderAnimation.vue';

import { MediaAuthoringHelper } from '@/helpers/MediaAuthoringHelper';
import { defineComponent } from 'vue';

let countDownInterval: any;
let mediaAuthoringHelper: MediaAuthoringHelper;

export default defineComponent({
    components: {
        LoaderAnimation,
        BtnStd,
    },

    emits: [
        'create-animated-card',
        'complete',
    ],

    mounted(): void {
        if (window.navigator.mediaDevices === undefined) {
            this.cameraUncompliant = true;
            this.showAlternativeBtn = true;
        } else {
            MediaAuthoringHelper.setup({
                audio: false,
                video: {
                    // facingMode: "user",
                    width: {
                        ideal: 1080,
                    },
                },
            }).then((mediaStream) => {
                if (mediaStream !== false) {
                    const { facingMode } = mediaStream.getVideoTracks()[0].getSettings();

                    if ((facingMode !== "user")
                        // && (process.env.NODE_ENV === 'production')
                    ) {
                        mediaStream.getVideoTracks()[0].stop();

                        this.cameraUncompliant = true;
                        this.showAlternativeBtn = true;
                    } else {
                        this.camera = true;

                        const onCameraPlay = (): void => {
                            (this.faceDetection.parentElement as HTMLDivElement).classList.remove('hidden');
                        }
                        const onFaceDetection = (mediaAuthoringHelper: MediaAuthoringHelper, xCenter: number, yCenter: number): void => {
                            if (!this.cameraRecordingFinished) {
                                if ((xCenter >= 20 && xCenter <= 70) && (yCenter >= 30 && yCenter <= 60)) {
                                    if (!this.cameraRecording) {
                                        this.cameraCanRecord = true;
                                    }
                                } else {
                                    if (countDownInterval) {
                                        clearInterval(countDownInterval);
                                    }

                                    this.cameraCanRecord = false;
                                    this.countDown = 6;

                                    if (this.cameraRecording) {
                                        this.cameraRecording = false;

                                        mediaAuthoringHelper.mediaRecorder?.stop();
                                    }
                                }
                            }
                        }
                        const onSelfieSegmentationReady = (): void => {
                            this.showLoaderAnimation = false;
                        };

                        this.$nextTick(() => {
                            mediaAuthoringHelper = new MediaAuthoringHelper(this.canvasContainer, {
                                faceDetectionFilePath: "/@mediapipe/face_detection@0.4",
                                frameRate: 25,
                                onCameraPlay,
                                onFaceDetection,
                                onSelfieSegmentationReady,
                                selfieSegmentationFilePath: "/@mediapipe/selfie_segmentation@0.4",
                                videoBgSrc: `/media/bg_video.mp4?${version}`,
                                vignetteSrc: `/img/vignette.jpg?${version}`,
                            }, mediaStream);
                        })
                    }
                } else {
                    this.cameraDenied = true;
                    this.showAlternativeBtn = true;
                }
            })
        }
    },

    data() {
        return {
            countDown: 6 as (string | number),
            showLoaderAnimation: true,
            camera: false,
            cameraCanRecord: false,
            cameraRecording: false,
            cameraRecordingFinished: false,

            cameraDenied: false,
            cameraUncompliant: false,

            showAlternativeBtn: false,
        }
    },

    computed: {
        lang(): string {
            return this.$i18n.locale.split('-')[0];
        },

        canvasContainer(): HTMLCanvasElement {
            return document.querySelector('.access_camera_screen_contents > section > canvas') as HTMLCanvasElement
        },
        faceDetection(): HTMLImageElement {
            return document.querySelector('.viewfinder > img.face-detection') as HTMLImageElement
        },
    },

    watch: {
        countDown(countDown: number | string): void {
            if (typeof countDown === 'string') {
                clearInterval(countDownInterval);
            }
        }
    },

    methods: {
        recording(): void {
            if (mediaAuthoringHelper) {
                this.cameraRecording = true;

                const capture = mediaAuthoringHelper.recording(6);
                capture.then((blob) => {
                    if (this.cameraCanRecord) {
                        (this.faceDetection.parentElement as HTMLDivElement)?.classList.add('hidden');

                        this.cameraRecording = false;
                        this.cameraRecordingFinished = true;

                        mediaAuthoringHelper.track.stop();

                        this.$emit('complete', blob);
                    }
                });

                countDownInterval = setInterval(() => {
                    if ((typeof this.countDown === 'number') && (this.countDown > 0)) {
                        this.countDown--;
                    } else {
                        this.countDown = '...';

                        if (countDownInterval) {
                            clearInterval(countDownInterval);
                        }
                    }
                }, 1000);
            }
        }
    },
})
