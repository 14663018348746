
import BtnStd from '@/components/BtnStd.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        BtnStd,
    },
    computed: {
        locale(): string {
            return this.$i18n.locale.substring(0, 2);
        },
    },
})
