import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dfd9d6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat_bar_input_block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_std = _resolveComponent("modal-std")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInput) = $event)),
      class: "field",
      autocomplete: "false",
      maxlength: "20",
      spellcheck: "false",
      type: "text",
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)), ["enter"]))
    }, null, 544), [
      [
        _vModelText,
        _ctx.formInput,
        void 0,
        { trim: true }
      ]
    ]),
    _createElementVNode("img", {
      class: "arrow",
      alt: "arrow",
      src: "/img/arrow.svg",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }),
    _createVNode(_component_modal_std, {
      cta: _ctx.hasProfanityCta,
      show: _ctx.hasProfanity,
      text: _ctx.$t('PROFANITY.TEXT'),
      title: _ctx.$t('PROFANITY.TITLE')
    }, null, 8, ["cta", "show", "text", "title"])
  ]))
}