import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e420bc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cont_msg" }
const _hoisted_2 = {
  key: 0,
  class: "video"
}
const _hoisted_3 = ["controls", "src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 2,
  class: "video"
}
const _hoisted_8 = ["controls", "src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.className)
    }, [
      (_ctx.video && !_ctx.bot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("video", {
              controls: _ctx.videoControls,
              src: _ctx.videoSrc,
              muted: "",
              oncontextmenu: "return false",
              playsinline: "",
              autoplay: "",
              preload: "auto",
              type: "video/mp4",
              onCanplay: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onVideoCanPlay && _ctx.onVideoCanPlay(...args))),
              onCanplaythrough: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onVideoCanPlayThrough && _ctx.onVideoCanPlayThrough(...args))),
              onEnded: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onVideoEnded && _ctx.onVideoEnded(...args))),
              onLoadeddata: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onVideoLoadedData && _ctx.onVideoLoadedData(...args))),
              onPlay: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onVideoPlay && _ctx.onVideoPlay(...args)))
            }, [
              _createElementVNode("source", {
                src: _ctx.videoSrc,
                type: "video/mp4"
              }, null, 8, _hoisted_4)
            ], 40, _hoisted_3)
          ]))
        : (!_ctx.bot)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true),
      _createElementVNode("img", {
        src: _ctx.imgSrc,
        alt: ""
      }, null, 8, _hoisted_6),
      (_ctx.video && _ctx.bot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("video", {
              controls: _ctx.videoControls,
              src: _ctx.videoSrc,
              muted: "",
              oncontextmenu: "return false",
              playsinline: "",
              autoplay: "",
              preload: "auto",
              type: "video/mp4",
              onCanplay: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onVideoCanPlay && _ctx.onVideoCanPlay(...args))),
              onCanplaythrough: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onVideoCanPlayThrough && _ctx.onVideoCanPlayThrough(...args))),
              onEnded: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onVideoEnded && _ctx.onVideoEnded(...args))),
              onLoadeddata: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onVideoLoadedData && _ctx.onVideoLoadedData(...args))),
              onPlay: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onVideoPlay && _ctx.onVideoPlay(...args)))
            }, [
              _createElementVNode("source", {
                src: _ctx.videoSrc,
                type: "video/mp4"
              }, null, 8, _hoisted_9)
            ], 40, _hoisted_8)
          ]))
        : (_ctx.bot)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true)
    ], 2)
  ]))
}