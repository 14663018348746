
import ModalStd from '@/components/ModalStd.vue';
import axios, { ResponseInterface } from '@/libraries/axios';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        ModalStd,
    },

    data() {
        return {
            formInput: null as (string | null),
            hasProfanity: false,
        }
    },

    computed: {
        hasProfanityCta() {
            return {
                callback: () => {
                    this.hasProfanity = false;
                    this.formInput = '';

                    (document.querySelector('.chat_bar_input_block input[type="text"]') as HTMLInputElement)?.focus();
                },
                label: this.$t('OK'),
            }
        }
    },

    methods: {
        onInput() {
            axios.post<ResponseInterface>('/:lang/profanity/moderate', {
                text: this.formInput,
            }).then(({data, status}) => {
                if (status === 200) {
                    const {code} = data;

                    if (code === 200) {
                        this.$emit('complete', this.formInput);
                    } else {
                        this.hasProfanity = true;
                    }
                } else {
                    this.hasProfanity = true;
                }
            }).catch(console.log);
        },
    },
});
