import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_animation = _resolveComponent("loader-animation")!
  const _component_sharing_actions = _resolveComponent("sharing-actions")!
  const _component_modal_std = _resolveComponent("modal-std")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showLoaderAnimation)
      ? (_openBlock(), _createBlock(_component_loader_animation, { key: 0 }))
      : (_openBlock(), _createBlock(_component_sharing_actions, {
          key: 1,
          "greeting-id": _ctx.greetingId,
          lottery: _ctx.lottery,
          "pin-code": _ctx.pinCode,
          video: _ctx.video
        }, null, 8, ["greeting-id", "lottery", "pin-code", "video"])),
    _createVNode(_component_modal_std, {
      cta: _ctx.modalStdCta,
      show: _ctx.hasError,
      text: _ctx.$t('ERRORS.GENERAL_ERROR_DESC'),
      title: _ctx.$t('ERRORS.GENERAL_ERROR_TITLE')
    }, null, 8, ["cta", "show", "text", "title"])
  ]))
}