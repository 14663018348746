import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: ['choice_btn', this.btnAppendClass, { noBorder: _ctx.noBorder }]
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.delayClick && _ctx.delayClick(...args)))
  }), _toDisplayString(_ctx.btnLabel), 17))
}