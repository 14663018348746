import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "landing_page" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_std = _resolveComponent("btn-std")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "landing_bg",
      src: `/img/${_ctx.locale}/text_intro.svg`,
      alt: "landing_bg"
    }, null, 8, _hoisted_2),
    _createVNode(_component_btn_std, {
      "btn-label": _ctx.$t('LETS_GET_STARTED'),
      type: "button",
      "no-border": "",
      onBtnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('agreed')))
    }, null, 8, ["btn-label"])
  ]))
}