import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6913dd30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "landing_page" }
const _hoisted_2 = { class: "loader-animation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: "text",
        style: _normalizeStyle('opacity: ' + (_ctx.working ? '0' : '1'))
      }, [
        (_ctx.tokens.length > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.tokens, (token, index) => {
              return (_openBlock(), _createElementBlock("span", {
                key: index,
                class: "token",
                style: _normalizeStyle('animation-delay: ' + (_ctx.speedTokens * index) + 'ms')
              }, _toDisplayString((index ? ' ' : '') + token), 5))
            }), 128))
          : _createCommentVNode("", true)
      ], 4)
    ])
  ]))
}