
import { defineComponent } from 'vue';

let timeoutId: any;

export default defineComponent({
    props: {
        interval: {
            default: 4500,
            required: false,
            type: Number,
        },
        speedTokens: {
            default: 300,
            required: false,
            type: Number,
        }
    },

    beforeMount() {
        this.spots.push(this.$t('LOADING.SPOT01'));
        this.spots.push(this.$t('LOADING.SPOT02'));
    },

    mounted(): void {
        this.tokens = this.spots[this.spotsIndex].split(' ');
        this.working = false;
    },

    beforeUnmount() {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    },

    data() {
        return {
            spots: [] as string[],
            spotsIndex: 0,
            working: true,
            tokens: [] as (string[]),
        }
    },

    watch: {
        spotsIndex(spotsIndex: number): void {
            if (spotsIndex >= this.spots.length) {
                this.spotsIndex = 0;
            } else {
                this.working = true;

                this.$nextTick(() => {
                    this.tokens = [];

                    this.$nextTick(() => {
                        this.tokens = this.spots[spotsIndex].split(' ');
                        this.working = false;
                    });
                });
            }
        },

        working(working: boolean): void {
            if (!working) {
                timeoutId = setTimeout(() => {
                    this.spotsIndex++;
                    timeoutId = null;
                }, this.interval + (this.speedTokens * this.tokens.length));
            }
        }
    },

});
