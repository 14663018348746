
import { SettingsHelper } from '@/helpers/SettingsHelper';
import { FormPayloadInterface } from '@/interfaces/ChatBotInterface';
import axios, { ResponseInterface } from '@/libraries/axios';
import ChatBot from '@/views/ChatBot.vue';
import DeliveryBot from '@/views/DeliveryBot.vue';
import LandingPage from '@/views/LandingPage.vue';
import MakeVideo from '@/views/MakeVideo.vue';
import { useStore } from '@/vuex/store';
import { pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { computed, defineComponent } from 'vue';
import LoadingLayer from "@/components/LoadingLayer.vue";
import AppHeader from "@/components/AppHeader.vue";

export default defineComponent({
    components: {
        AppHeader,
        LoadingLayer,
        ChatBot,
        DeliveryBot,
        LandingPage,
        MakeVideo,
    },

    setup() {
        const store = useStore();

        return {
            makeVideo: computed(() => store.state.makeVideo),
        }
    },

    beforeMount(): void {
        if (!SettingsHelper.has('system', 'app', 'uuid')) {
            SettingsHelper.set('system', 'app', 'uuid', uuidv4());
        }

        SettingsHelper.set('system', 'app', 'language', this.$i18n.locale);

        axios.get<ResponseInterface>('/:lang/hello').then(({ data, status }) => {
            if (status === 200 && data.code === 218) {
                const pyld = data.pyld as Record<string, unknown>;

                SettingsHelper.set('system', 'app', 'language', pyld.language);

                this.$i18n.locale = pyld.language as string;

                SettingsHelper.set('system', 'env', 'available', pyld.available);
                SettingsHelper.set('system', 'env', 'hasLottery', pyld.hasLottery);
                SettingsHelper.set('system', 'env', 'hasTree', pyld.hasTree);
                SettingsHelper.set('system', 'env', 'urlLottery', pyld.urlLottery);
                SettingsHelper.set('system', 'env', 'instance', pyld.instance);
                SettingsHelper.set('system', 'env', 'videoTtl', pyld.videoTtl);

                this.greetingId = new URLSearchParams(location.search).get('g');

                if (!this.greetingId) {
                    this.landingPageShow = SettingsHelper.get('system', 'landingPage', 'show', true);
                    this.chatBotShow = !this.landingPageShow;
                } else {
                    this.showDeliveryBot = true;
                }

                this.loader = !pyld.available;
            }
        });
    },

    data() {
        return {
            capture: null as (Blob | null),
            chatBotShow: false,
            createAnimatedCard: false,
            greetingId: null as (string | null),
            loader: true,
            landingPageShow: true,
            showDeliveryBot: false,
        }
    },

    methods: {
        landingPageAgreed(): void {
            this.chatBotShow = true;
            this.landingPageShow = false;

            SettingsHelper.set('system', 'landingPage', 'show', false);
        },

        onCreateAnimatedCard(): void {
            SettingsHelper.set('form', 'chatbot', 'greetingsType', 'MAKE_THE_ANIMATED_CARD');

            this.createAnimatedCard = true;
            this.$store.state.makeVideo = false;
        },

        onComplete(post: FormPayloadInterface): void {
            this.showDeliveryBot = true;

            axios.post<ResponseInterface>('/:lang/greetings', pick(post, [
                'greetingsText',
                'recipientName',
                'senderName',
            ])).then(({ data, status }) => {
                if ((status === 200) && (data.code === 201)) {
                    const { id: greetingId, token  } = (data.pyld as Record<string, unknown>);

                    if (greetingId !== null) {
                        this.greetingId = greetingId as string;

                        window.history.replaceState({}, document.title, location.href + `?g=${greetingId}`);

                        const formData = new FormData();
                        formData.set('to', post.recipientName);
                        formData.set('from', this.$t('FROM', { senderName: post.senderName }));
                        formData.set('message', post.greetingsText);

                        if (post.greetingsType === 'MAKE_THE_ANIMATED_CARD') {
                            formData.set('card', String(post.animatedCard));
                        } else if (post.greetingsType === 'MAKE_THE_VIDEO_CARD') {
                            formData.set('video', this.capture as Blob);
                        }

                        axios.post(`/api/v2/video/${greetingId}`, formData, {
                            baseURL: process.env.VUE_APP_MEDIASERVER_API,
                            headers: {
                                'accept': 'application/json',
                                'authorization': `Bearer ${token}`,
                                'content-type': 'multipart/form-data',
                            }
                        });
                    }
                }
            })
        },

        onVideoCapture(blob: Blob) {
            this.capture = blob;
            this.$store.state.makeVideo = false;
        },
    }
});
