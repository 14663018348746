import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ada6459"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "links_container" }
const _hoisted_2 = {
  href: "https://google.com",
  target: "_blank"
}
const _hoisted_3 = {
  href: "https://google.com",
  target: "_blank"
}
const _hoisted_4 = {
  href: "https://google.com",
  target: "_blank"
}
const _hoisted_5 = { class: "text_under_message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_text = _resolveComponent("message-text")!
  const _component_message_choice = _resolveComponent("message-choice")!
  const _component_btn_std = _resolveComponent("btn-std")!
  const _component_input_bar = _resolveComponent("input-bar")!
  const _component_input_slider = _resolveComponent("input-slider")!
  const _component_btn_back = _resolveComponent("btn-back")!
  const _component_modal_std = _resolveComponent("modal-std")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chat_container", { withInputBar: _ctx.inputBarRef !== null }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.$t('BTN_PRIVACY_POLICY.LABEL')), 1),
      _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t('BTN_COOKIE_POLICY.LABEL')), 1),
      _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('BTN_TERMS_OF_USE.LABEL')), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat, (c, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (c.withUndernote)
          ? (_openBlock(), _createElementBlock("div", {
              key: `DIV${index}`
            }, [
              (c.message !== undefined)
                ? (_openBlock(), _createBlock(_component_message_text, _mergeProps({
                    key: 0,
                    key: index
                  }, c), null, 16))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.$t('TEXT_UNDER_MESSAGE')) + " ", 1),
                _createElementVNode("a", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openPolicy(_ctx.$t('BTN_PRIVACY_POLICY.LABEL'), 'privacy-notice')))
                }, _toDisplayString(_ctx.$t('BTN_PRIVACY_POLICY.LABEL')), 1)
              ])
            ]))
          : (c.message !== undefined || c.video !== undefined)
            ? (_openBlock(), _createBlock(_component_message_text, _mergeProps({
                key: 1,
                key: index
              }, c), null, 16))
            : _createCommentVNode("", true),
        (c.choice !== undefined)
          ? (_openBlock(), _createBlock(_component_message_choice, _mergeProps({
              key: 2,
              key: index
            }, c, { onPick: _ctx.onPickChoice }), null, 16, ["onPick"]))
          : _createCommentVNode("", true),
        (c.button !== undefined)
          ? (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "choice_container"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(c.button, ({label, onClick}, index) => {
                return (_openBlock(), _createBlock(_component_btn_std, {
                  key: `BTN-STD${index}`,
                  "btn-label": label,
                  onBtnClick: onClick
                }, null, 8, ["btn-label", "onBtnClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256)),
    (_ctx.inputBarRef !== null)
      ? (_openBlock(), _createBlock(_component_input_bar, {
          key: 0,
          onComplete: _ctx.onInputBar
        }, null, 8, ["onComplete"]))
      : _createCommentVNode("", true),
    (_ctx.inputSliderRef !== null)
      ? (_openBlock(), _createBlock(_component_input_slider, {
          key: 1,
          "recipient-name": _ctx.recipientName,
          referrer: _ctx.inputSliderRef,
          slides: _ctx.inputSliderSlides,
          type: _ctx.inputSliderSlidesType,
          onComplete: _ctx.onInputSlider,
          onLoaded: _ctx.toBottom
        }, null, 8, ["recipient-name", "referrer", "slides", "type", "onComplete", "onLoaded"]))
      : _createCommentVNode("", true),
    (_ctx.backTo)
      ? (_openBlock(), _createBlock(_component_btn_back, {
          key: 2,
          label: _ctx.$t('BTN_BACK.LABEL'),
          onClick: _ctx.goTo
        }, null, 8, ["label", "onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_modal_std, {
      show: _ctx.modalShow,
      text: _ctx.modalText,
      title: _ctx.modalTitle,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalShow = false))
    }, null, 8, ["show", "text", "title"])
  ], 2))
}