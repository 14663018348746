
import ModalStd from '@/components/ModalStd.vue';
import { SettingsHelper } from '@/helpers/SettingsHelper';
import axios, { ResponseInterface } from '@/libraries/axios';
import LoaderAnimation from '@/views/DeliveryBot/LoaderAnimation.vue';
import SharingActions from '@/views/DeliveryBot/SharingActions.vue';
import { defineComponent } from 'vue';

let timeoutId: any;

export default defineComponent({
    components: {
        LoaderAnimation,
        ModalStd,
        SharingActions,
    },

    props: {
        greetingId: {
            default: null,
            required: false,
            type: String,
        }
    },

    beforeMount(): void {
        SettingsHelper.del('form', 'chatbot');

        if (this.greetingId) {
            this.infos();
        }
    },

    beforeUnmount(): void {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    },

    data() {
        return {
            hasError: false,
            lottery: null as (null | string),
            pinCode: null as (null | string),
            showLoaderAnimation: true,
            video: null as (null | string),
        }
    },

    computed: {
        modalStdCta() {
            return {
                callback() {
                    location.href = location.origin + location.pathname;
                },
                label: this.$t('ERRORS.GENERAL_ERROR_CTA'),
            };
        },
    },

    watch: {
        greetingId(greetingId: null | string, prev: null | string): void {
            if (greetingId !== null && prev === null) {
                this.infos();
            }
        },
    },

    methods: {
        infos(): void {
            axios.get<ResponseInterface>('/:lang/greetings', {
                params: {
                    id: this.greetingId,
                },
            }).then(({ data, status }) => {
                if ((status === 200) && (data.code === 302)) {
                    const {
                        lottery,
                        pin_code: pinCode,
                        video,
                    } = data.pyld as {
                        completed_at: string | null;
                        id: string;
                        lottery: string;
                        pin_code: string;
                        video: string | null;
                    };

                    if (video === null) {
                        timeoutId = setTimeout(() => {
                            this.infos();

                            timeoutId = null;
                        }, 10_000);
                    } else {
                        this.video = video;
                        this.showLoaderAnimation = false;
                        this.lottery = lottery;
                        this.pinCode = pinCode;
                    }
                } else {
                    this.hasError = true;
                }
            }).catch(() => this.hasError = true);
        },
    },
});
