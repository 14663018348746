
import BtnStd from '@/components/BtnStd.vue';
import ModalStd from '@/components/ModalStd.vue';
import { SettingsHelper } from '@/helpers/SettingsHelper';
import {
    ChatBotInterface,
    Choice0Value,
    Choice1Value,
    Choice2Value,
    FormPayloadInterface,
    InputSliderPayloadInterface,
    MessageChoicePayloadInterface
} from '@/interfaces/ChatBotInterface';
import axios from '@/libraries/axios';
import BtnBack from '@/views/ChatBot/BtnBack.vue';
import InputBar from '@/views/ChatBot/InputBar.vue';
import InputSlider from '@/views/ChatBot/InputSlider.vue';
import MessageChoice from '@/views/ChatBot/MessageChoice.vue';
import MessageText from '@/views/ChatBot/MessageText.vue';
import { useStore } from '@/vuex/store';
import { findIndex, forEach, map } from 'lodash';
import { computed, defineComponent } from 'vue';

let greetings: Record<string, string[]>;
let cards: string[];

export default defineComponent({
    components: {
        BtnBack,
        BtnStd,
        InputBar,
        InputSlider,
        MessageChoice,
        MessageText,
        ModalStd,
    },

    setup() {
        const store = useStore();

        return {
            makeVideo: computed(() => store.state.makeVideo),
        };
    },

    async beforeMount(): Promise<void> {
        const {
            data,
            status
        } = await axios.get(`/greetings/${SettingsHelper.get('system', 'app', 'language')}.json`,
            { baseURL: '' });

        if (status === 200) {
            greetings = data;
        }

        await this.pushMessage(() => {
            this.chat.push({
                bot: true,
                hideAvatar: true,
                message: this.$t('BOT_0.MESSAGE'),
            });
        });
        await this.pushMessage(() => {
            this.chat.push({
                bot: true,
                message: this.$t('BOT_1.MESSAGE'),
            });
        }, 200);

        if (await this.__recipientType() &&
            await this.__recipientName() &&
            await this.__greetingsText() &&
            await this.__senderName()) {
            await this.__greetingsType();
        }
    },

    mounted() {
        cards = [
            `/img/${this.lang}/cards/1.gif`,
            `/img/${this.lang}/cards/2.gif`,
            `/img/${this.lang}/cards/3.gif`,
        ];

        const preloader: string[] = JSON.parse(JSON.stringify(cards));
        preloader.push('/media/loader.mov');
        preloader.push('/media/loader.webm');

        forEach(preloader, (file) => {
            let loader: HTMLImageElement | null = new Image();
            loader.src = file;
            loader.onload = () => loader = null;
        });

        window.addEventListener('resize', this.onResize);

        this.onResize();
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.toBottom);
    },

    props: {
        createAnimatedCard: {
            default: false,
            type: Boolean,
        },
        video: {
            required: false,
            type: Blob,
        },
    },

    data() {
        return {
            backTo: false,
            chat: [] as ChatBotInterface[],
            inputBarRef: null as (number | string | null),
            inputSliderRef: null as (number | string | null),

            modalShow: false,
            modalText: '',
            modalTitle: '',

            animatedCard: null as (number | null),
            greetingsText: null as (string | null),
            greetingsType: null as (Choice1Value | null),
            recipientType: null as (Choice0Value | null),
            recipientName: null as (string | null),
            senderName: null as (string | null),
        };
    },

    computed: {
        form(): FormPayloadInterface {
            return {
                animatedCard: this.animatedCard as number,
                greetingsText: this.greetingsText as string,
                greetingsType: this.greetingsType as string,
                recipientName: this.recipientName as string,
                recipientType: this.recipientType as string,
                senderName: this.senderName as string,
            };
        },

        inputSliderSlides(): string[] {
            if (this.step === 5) {
                return cards;
            } else {
                return map(greetings[this.recipientType as Choice0Value], (greeting) => {
                    return greeting.replace('{name}', this.recipientName as string);
                });
            }
        },
        inputSliderSlidesType(): string {
            return (this.step === 5) ? 'image' : 'text';
        },

        lang(): string {
            return this.$i18n.locale.split('-')[0];
        },

        step(): number {
            let step = 0;

            if (this.form.animatedCard) {
                step++;
            }

            if (this.form.greetingsText) {
                step++;
            }

            if (this.form.greetingsType) {
                step++;
            }

            if (this.form.recipientName) {
                step++;
            }

            if (this.form.recipientType) {
                step++;
            }

            if (this.form.senderName) {
                step++;
            }

            return step;
        },
    },

    watch: {
        backTo(): void {
            this.toBottom();
        },

        createAnimatedCard(status: boolean) {
            if (status) {
                if (SettingsHelper.has('form', 'chatbot', 'greetingsType')) {
                    this.greetingsType = SettingsHelper.get('form', 'chatbot', 'greetingsType');

                    this.chat = this.chat.slice(0, this.chat.length - 5);

                    this.__greetingsType()
                        .then(() => {
                            this.$nextTick(async () => {
                                await this.onPickChoice({
                                    choice: SettingsHelper.get('form', 'chatbot', 'greetingsType'),
                                    referrer: 'greetingsType',
                                });
                            });
                        });
                }
            }
        },

        modalShow(status: boolean): void {
            if (!status) {
                this.modalText = '';
                this.modalTitle = '';
            }
        },

        makeVideo(status: boolean, prevStatus: boolean): void {
            if (!status && prevStatus) {
                this.toBottom();
            }
        },

        animatedCard(animatedCard: string | null, prev: string | null): void {
            if ((animatedCard !== null) && (animatedCard !== prev)) {
                SettingsHelper.set('form', 'chatbot', 'animatedCard', animatedCard);
            } else {
                SettingsHelper.del('form', 'chatbot', 'animatedCard');
            }
        },
        greetingsText(greetingsText: string | null, prev: string | null): void {
            if ((greetingsText !== null) && (greetingsText !== prev)) {
                SettingsHelper.set('form', 'chatbot', 'greetingsText', greetingsText);
            } else {
                SettingsHelper.del('form', 'chatbot', 'greetingsText');
            }
        },
        greetingsType(greetingsType: string | null, prev: string | null): void {
            if ((greetingsType !== null) && (greetingsType !== prev)) {
                SettingsHelper.set('form', 'chatbot', 'greetingsType', greetingsType);
            } else {
                SettingsHelper.del('form', 'chatbot', 'greetingsType');
            }
        },
        recipientName(recipientName: string | null, prev: string | null): void {
            if ((recipientName !== null) && (recipientName !== prev)) {
                SettingsHelper.set('form', 'chatbot', 'recipientName', recipientName);
            } else {
                SettingsHelper.del('form', 'chatbot', 'recipientName');
            }
        },
        recipientType(recipientType: string | null, prev: string | null): void {
            if ((recipientType !== null) && (recipientType !== prev)) {
                SettingsHelper.set('form', 'chatbot', 'recipientType', recipientType);
            } else {
                SettingsHelper.del('form', 'chatbot', 'recipientType');
            }
        },
        senderName(senderName: string | null): void {
            if (senderName !== null) {
                SettingsHelper.set('form', 'chatbot', 'senderName', senderName);
            } else {
                SettingsHelper.del('form', 'chatbot', 'senderName');
            }
        },

        video(video: Blob | null): void {
            if (video !== null) {
                this.chat = this.chat.slice(0, this.chat.length - 1);

                this.__videoCaptured();
            }
        }
    },

    methods: {
        goTo(): void {
            this.animatedCard = null;
            this.greetingsText = null;
            this.greetingsType = null;
            this.recipientName = null;
            this.recipientType = null;
            this.senderName = null;
            this.inputSliderRef = null;
            this.backTo = false;

            this.chat = this.chat.slice(0, 2);

            this.$nextTick(() => this.__recipientType());
        },
        onResize(): void {
            this.toBottom();
            document.documentElement.style.setProperty('--doc-height', window.innerHeight + 'px');
        },
        openPolicy(title: string, context: string): void {
            axios.get(`/static/:lang/${context}.html`, {
                baseURL: '',
            })
                .then(({ data }) => {
                    this.modalText = data;
                    this.modalTitle = title;

                    this.modalShow = true;
                });
        },

        pushMessage(callback: () => void, wait = 500): Promise<void> {
            return new Promise((resolve) => {
                this.$nextTick(() => {
                    setTimeout(() => {
                        callback();

                        this.toBottom();
                        resolve();
                    }, wait);
                });
            });
        },

        toBottom(): void {
            this.$nextTick(() => {
                window.scrollTo(0, document.body.scrollHeight);
                /*(document.querySelector('#toBottom') as HTMLDivElement).scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                });*/
            });
        },

        async onInputBar(value: string): Promise<void> {
            const ref = this.inputBarRef;
            this.inputBarRef = null;

            switch (ref) {
                case 2:
                    this.recipientName = value;

                    await this.pushMessage(() => {
                        this.chat.push({
                            bot: false,
                            message: this.recipientName as string,
                            withUndernote: true,
                        });
                    });

                    await this.__greetingsText();
                    break;

                case 4:
                    this.senderName = value;

                    await this.__greetingsType();
                    break;
            }
        },

        async onInputSlider({
            choice,
            referrer
        }: InputSliderPayloadInterface): Promise<void> {
            if (referrer === 'greetingsText') {
                this.greetingsText = choice;
                this.inputSliderRef = null;

                await this.pushMessage(() => {
                    this.chat.push({
                        bot: false,
                        message: this.greetingsText as string,
                    });
                });

                await this.__senderName();
            } else {
                if (referrer === 'animatedCard') {
                    this.animatedCard = findIndex(cards, (card) => card === choice);
                    this.inputSliderRef = null;

                    this.$emit('complete', this.form);
                }
            }
        },

        async onPickChoice({
            choice,
            referrer
        }: MessageChoicePayloadInterface): Promise<void> {
            if (referrer === 'recipientType') {
                this.recipientType = choice as Choice0Value;

                await this.pushMessage(() => {
                    this.chat[this.chat.length - 1] = {
                        bot: false,
                        message: this.$t(`BOT_1.CHOICES.${this.recipientType}`),
                    };
                });
                await this.__recipientName();
            } else {
                if (referrer === 'greetingsType') {
                    this.greetingsType = choice as Choice1Value;

                    await this.pushMessage(() => {
                        this.chat[this.chat.length - 1] = {
                            bot: false,
                            message: this.$t(`BOT_5.CHOICES.${this.greetingsType}`),
                        };
                    });

                    if (choice === 'MAKE_THE_ANIMATED_CARD') {
                        await this.__animatedCardType();
                    } else {
                        if (choice === 'MAKE_THE_VIDEO_CARD') {
                            await this.__videoCardType();
                        }
                    }
                } else {
                    if (referrer === 'videoCapture') {
                        const _choice = choice as Choice2Value;

                        if (_choice === 'YES') {
                            this.$emit('complete', this.form);
                        } else {
                            this.chat = this.chat.slice(0, 8);

                            this.$nextTick(() => this.__greetingsType());
                        }
                    }
                }
            }
        },

        __animatedCardType(): Promise<void> {
            this.backTo = false;

            return new Promise<void>((resolve) => {
                this.pushMessage(() => {
                    this.chat.push({
                        bot: true,
                        message: this.$t('BOT_6B.MESSAGE'),
                    });
                    this.inputSliderRef = 'animatedCard';
                })
                    .then(() => {
                        this.backTo = true;

                        resolve();
                    });
            });
        },

        __greetingsText(): Promise<boolean> {
            this.backTo = false;

            return new Promise<boolean>((resolve) => {
                if (SettingsHelper.get('form', 'chatbot', 'greetingsText') !== null) {
                    this.greetingsText = SettingsHelper.get('form', 'chatbot', 'greetingsText');

                    this.pushMessage(() => {
                        this.chat.push({
                            bot: true,
                            message: this.$t('BOT_3.MESSAGE', { name: this.recipientName }),
                        });
                    }).then(() => {
                        this.pushMessage(() => {
                            this.chat.push({
                                bot: false,
                                message: this.greetingsText as string,
                            });
                        }).then(() => resolve(true));
                    });
                } else {
                    this.pushMessage(() => {
                        this.chat.push({
                            bot: true,
                            message: this.$t('BOT_3.MESSAGE', { name: this.recipientName }),
                        });
                        this.inputSliderRef = 'greetingsText';
                    }).then(() => {
                        this.backTo = true;

                        resolve(false);
                    });
                }
            });
        },

        __greetingsType(): Promise<void> {
            this.backTo = false;

            return new Promise<void>((resolve) => {
                this.pushMessage(() => {
                    this.chat.push({
                        bot: false,
                        message: this.senderName as string,
                        withUndernote: true,
                    });
                }).then(() => {
                    this.pushMessage(() => {
                        this.chat.push({
                            bot: true,
                            message: this.$t('BOT_5.MESSAGE'),
                        });
                        this.chat.push({
                            choice: map(['MAKE_THE_VIDEO_CARD', 'MAKE_THE_ANIMATED_CARD'],
                                (value: Choice1Value) => {
                                    return {
                                        value,
                                        text: this.$t(`BOT_5.CHOICES.${value}`),
                                    };
                                }),
                            referrer: 'greetingsType',
                        });
                    }).then(() => {
                        this.backTo = true;

                        resolve();
                    });
                });
            });
        },

        __recipientName(): Promise<boolean> {
            this.backTo = false;

            return new Promise<boolean>((resolve) => {
                if (SettingsHelper.get('form', 'chatbot', 'recipientName') !== null) {
                    this.recipientName = SettingsHelper.get('form', 'chatbot', 'recipientName');

                    this.pushMessage(() => {
                        this.chat.push({
                            bot: true,
                            message: this.$t('BOT_2.MESSAGE'),
                        });
                    })
                        .then(() => {
                            this.pushMessage(() => {
                                this.chat.push({
                                    bot: false,
                                    message: this.recipientName as string,
                                    withUndernote: true,
                                });
                            })
                                .then(() => resolve(true));
                        });
                } else {
                    this.pushMessage(() => {
                        this.chat.push({
                            bot: true,
                            message: this.$t('BOT_2.MESSAGE'),
                        });
                        this.inputBarRef = 2;
                    })
                        .then(() => {
                            this.backTo = true;

                            resolve(false);
                        });
                }
            });
        },

        __recipientType(): Promise<boolean> {
            this.backTo = false;

            return new Promise<boolean>((resolve) => {
                if (SettingsHelper.get('form', 'chatbot', 'recipientType') !== null) {
                    this.recipientType = SettingsHelper.get('form', 'chatbot', 'recipientType');

                    this.pushMessage(() => {
                        this.chat.push({
                            bot: false,
                            message: this.$t(`BOT_1.CHOICES.${this.recipientType}`),
                        });
                    })
                        .then(() => resolve(true));
                } else {
                    this.pushMessage(() => {
                        this.chat.push({
                            choice: map(
                                ['A_BELOVED_ONE', 'A_FAMILY_MEMBER', 'A_FRIEND', 'A_COLLEAGUE'],
                                (value: Choice0Value) => {
                                    return {
                                        value,
                                        text: this.$t(`BOT_1.CHOICES.${value}`),
                                    };
                                }),
                            referrer: 'recipientType',
                        });
                    })
                        .then(() => resolve(false));
                }
            });
        },

        __senderName(): Promise<boolean> {
            this.backTo = false;

            return new Promise<boolean>((resolve) => {
                if (SettingsHelper.get('form', 'chatbot', 'senderName') !== null) {
                    this.senderName = SettingsHelper.get('form', 'chatbot', 'senderName');

                    this.pushMessage(() => {
                        this.chat.push({
                            bot: true,
                            message: this.$t('BOT_4.MESSAGE'),
                        });
                    })
                        .then(() => resolve(true));
                } else {
                    this.pushMessage(() => {
                        this.chat.push({
                            bot: true,
                            message: this.$t('BOT_4.MESSAGE'),
                        });
                        this.inputBarRef = 4;
                    })
                        .then(() => {
                            this.backTo = true;

                            resolve(false);
                        });
                }
            });
        },

        __videoCardType(): Promise<void> {
            this.backTo = false;

            return new Promise<void>((resolve) => {
                this.pushMessage(() => {
                    this.chat.push({
                        bot: true,
                        message: this.$t('BOT_6A.MESSAGE'),
                    });
                })
                    .then(() => {
                        this.pushMessage(() => {
                            this.chat.push({
                                button: [
                                    {
                                        label: this.$t('BOT_6A.ACTION'),
                                        onClick: () => {
                                            this.$store.state.makeVideo = true;
                                        }
                                    }
                                ],
                            });
                        })
                            .then(() => {
                                this.backTo = true;

                                resolve();
                            });
                    });
            });
        },

        __videoCaptured(): Promise<void> {
            this.backTo = false;

            return new Promise<void>((resolve) => {
                this.pushMessage(() => {
                    this.chat.push({
                        bot: false,
                        video: this.video,
                    });
                })
                    .then(() => {
                        this.pushMessage(() => {
                            this.chat.push({
                                bot: true,
                                message: this.$t('BOT_7.MESSAGE'),
                            });
                        })
                            .then(() => {
                                this.pushMessage(() => {
                                    this.chat.push({
                                        choice: map(['YES', 'NO'], (value: Choice2Value) => {
                                            return {
                                                value,
                                                text: this.$t(`BOT_7.CHOICES.${value}`),
                                            };
                                        }),
                                        referrer: 'videoCapture',
                                    });
                                })
                                    .then(() => resolve());
                            });
                    });
            });
        }
    }
});
