import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/cross.png'


const _withScopeId = n => (_pushScopeId("data-v-200dd312"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal"
}
const _hoisted_2 = { class: "window" }
const _hoisted_3 = {
  key: 1,
  class: "header_text"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_std = _resolveComponent("btn-std")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.cta)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "",
                class: "close_modal_icon",
                src: _imports_0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }))
            : _createCommentVNode("", true),
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "normal_text",
            innerHTML: _ctx.text
          }, null, 8, _hoisted_4),
          (_ctx.cta)
            ? (_openBlock(), _createBlock(_component_btn_std, {
                key: 2,
                style: {"margin-top":"15px"},
                "btn-label": _ctx.cta.label,
                onBtnClick: _ctx.cta.callback
              }, null, 8, ["btn-label", "onBtnClick"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}