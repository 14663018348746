import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f588aa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sharing-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message_text = _resolveComponent("message-text")!
  const _component_message_choice = _resolveComponent("message-choice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_message_text, {
      class: _normalizeClass(_ctx.showLargeVideo ? 'large-video' : null),
      "hide-avatar": !_ctx.showLargeVideo,
      video: _ctx.video,
      bot: "",
      style: {"margin-top":"150px"},
      onVideoEnded: _ctx.onVideoEnded,
      onVideoLoadedData: _ctx.onVideoLoadedData,
      onVideoPlay: _ctx.onVideoPlay
    }, null, 8, ["class", "hide-avatar", "video", "onVideoEnded", "onVideoLoadedData", "onVideoPlay"]),
    (!_ctx.showLargeVideo)
      ? (_openBlock(), _createBlock(_component_message_text, {
          key: 0,
          message: _ctx.$t('SHARING_01.MESSAGE'),
          bot: ""
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (!_ctx.showLargeVideo)
      ? (_openBlock(), _createBlock(_component_message_choice, {
          key: 1,
          choice: _ctx.messageChoiceSharing,
          referrer: "messageChoiceSharing",
          style: {"margin":"0 auto","width":"86%"},
          onPick: _ctx.onMessageChoicePick
        }, null, 8, ["choice", "onPick"]))
      : _createCommentVNode("", true),
    (_ctx.penultimate && !_ctx.ultimate)
      ? (_openBlock(), _createBlock(_component_message_text, {
          key: 2,
          message: _ctx.$t('SHARING_03.MESSAGE'),
          bot: ""
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (_ctx.penultimate && !_ctx.ultimate)
      ? (_openBlock(), _createBlock(_component_message_choice, {
          key: 3,
          choice: _ctx.messageChoiceAgain,
          referrer: "messageChoiceAgain",
          style: {"margin":"0 auto","width":"86%"},
          onPick: _ctx.onMessageChoicePick
        }, null, 8, ["choice", "onPick"]))
      : _createCommentVNode("", true),
    (_ctx.ultimate)
      ? (_openBlock(), _createBlock(_component_message_text, {
          key: 4,
          message: _ctx.$t('SHARING_04.MESSAGE'),
          "hide-avatar": _ctx.ultimate && (_ctx.hasSweepstakes && _ctx.showSweepstakes),
          bot: ""
        }, null, 8, ["message", "hide-avatar"]))
      : _createCommentVNode("", true),
    (_ctx.ultimate && (_ctx.hasSweepstakes && _ctx.showSweepstakes))
      ? (_openBlock(), _createBlock(_component_message_text, {
          key: 5,
          message: _ctx.$t('SHARING_05.MESSAGE'),
          bot: ""
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (_ctx.ultimate && _ctx.hasSweepstakes && _ctx.showSweepstakes)
      ? (_openBlock(), _createBlock(_component_message_choice, {
          key: 6,
          choice: _ctx.messageChoiceSweepstakes,
          referrer: "messageChoiceSweepstakes",
          style: {"margin":"0 auto","width":"86%"},
          onPick: _ctx.onMessageChoicePick
        }, null, 8, ["choice", "onPick"]))
      : _createCommentVNode("", true)
  ]))
}